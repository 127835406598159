import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Unfortunately but understandably, our headline speakers `}<a parentName="p" {...{
        "href": "https://www.instagram.com/p/B9kCtxHhm08/"
      }}>{`have postponed`}</a>{` the Commercial Classics North American tour due to travel concerns, including their talk in Vancouver at Type Brigade №50.`}</p>
    <p>{`Considering their significance to the event, and the safety and comfort of our community, `}<strong parentName="p">{`we have decided to cancel Type Brigade №50`}</strong>{` on March 30, 2020.`}</p>
    <p>{`While we are not a large-scale public gathering, as you most likely know, the public health and travel situation due to coronavirus (COVID-19) is changing rapidly. We want to be able to host a high-quality, safe, and enjoyable event for our attendees. Rather than reconfigure our event in the short term, we have decide to cancel the conference, and look at options further into the future instead.`}</p>
    <h2>{`Refunds`}</h2>
    <p>{`A full refund for your ticket will be initiated this week. I will be emailing you more details to the email address you used for your purchase. If you have any questions, please email `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com?subject=Type%20Brigade%2050%20refund"
      }}>{`kenneth@typebrigade.com`}</a>{`.`}</p>
    <p>{`We’ll keep you updated on the future of the conference and the meetup as we have more specific details to share. If you haven’t already, `}<a parentName="p" {...{
        "href": "https://meetup.com/typebrigade"
      }}>{`please join Type Brigade on Meetup.com for free`}</a>{`, where we announce all our events.`}</p>
    <h2>{`Thank you`}</h2>
    <p>{`We’re very proud to be part of the type and design community here in Vancouver. We sincerely appreciate all the support from attendees, organizations, sponsors, volunteers, friends, and speakers that have helped Type Brigade grow to nearly 2,000 members and hold 36 events since the current team took over around 5 years ago.`}</p>
    <p>{`Thank you for understanding, and for being part of Type Brigade.`}</p>
    <p>{`—Kenneth, and the Type Brigade team`}</p>
    <p><a parentName="p" {...{
        "href": "https://alannamunro.com"
      }}>{`Alanna Munro`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://kennethormandy.com"
      }}>{`Kenneth Ormandy`}</a><br />{`
`}<a parentName="p" {...{
        "href": "http://nancywudesign.com"
      }}>{`Nancy Wu`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://joshuamann.ca"
      }}>{`Joshua Mann`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      